<template>
	<phi-page id="compose">
		<div slot="top">
			<phi-page-side-panel ref="panel">
				<phi-person-relevance-selector-block
					:personId="$store.state.user.id"
					:selection="audience"
				>
					<div slot="top-left">
						<mu-icon value="arrow_back" @click="$refs.panel.close()" />
					</div>
				</phi-person-relevance-selector-block>
			</phi-page-side-panel>
		</div>

		<div slot="toolbar" class="page-toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ post.replyTo ? $t("action.reply") : $t("action.redact") }}</h1>
			<mu-icon-button @click="send" icon="send" />
		</div>
		<div v-if="post.id" class="compose-form">
			<div class="field-to" @click="$refs.panel.toggle()" v-show="!isReplyTarget">
				<template v-if="audience.length >= 1 && audience.length <= 3">
					<span>{{ $t("noun.to") }}: </span>
					<span class="namelist">
						<span v-for="person in audience" :key="person.id">{{ getPersonName(person) }}</span>
					</span>
				</template>
				<template v-else>
					<span v-if="audience.length">{{ $t("notice.ToNPeople", {n:audience.length}) }}</span>
					<span v-else>{{ $t("noun.to") }}</span>
				</template>
			</div>

			<phi-post-editor :post="post" :show-calendar="false" :show-subject="!post.replyTo">
				<div slot="after-body" v-if="post.replyTo" class="compose-trail">
					<div class="trail-toggler">
						<span @click="$refs.trail.toggle()">···</span>
					</div>

					<phi-drawer ref="trail">
						<div class="trail-contents">
							<phi-post-trail :post="trail"></phi-post-trail>
						</div>
					</phi-drawer>
				</div>
			</phi-post-editor>

			<div v-if="forwards.length" class="quotes">
				<div v-for="quote in forwards" class="phi-media quote" :key="quote.id">
					<div class="phi-media-figure phi-avatar">
						<img :src="quote.author.avatar" :alt="quote.author.firstName" />
					</div>
					<div class="phi-media-body">
						<div class="post-author">{{quote.author.firstName}} {{quote.author.lastName}}</div>
						<div class="post-description" v-html="quote.description"></div>
						<div class="post-blocks" v-if="quote.blocks.length">
							<phi-block
								v-for="block in quote.blocks"
								:block="block"
								:key="block.id"
							></phi-block>
						</div>
					</div>
				</div>
			</div>
		</div>

	</phi-page>
</template>

<script>
import store from '@/store/index.js';

import PhiPostTrail from '@/components/Phi/Post/Trail.vue';
import PhiPostEditor from '@/components/Phi/Post/Editor.vue';
import PhiBlock from '@/components/Phi/Block.vue';
import PhiPageSidePanel from '@/components/Phi/Page/SidePanel.vue';
import PhiPersonRelevanceSelectorBlock from '@/components/Phi/Person/Relevance/SelectorBlock.vue';

export default {
	components: {
		PhiPostTrail,
		PhiPostEditor,
		PhiBlock,
		PhiPageSidePanel,
		PhiPersonRelevanceSelectorBlock
	},

	data() {
		return {
			post: {
                id: null,
				title: null,
				description: null
			},
			audience: [],
			forwards: [],
			trail: [],
			isSent: false,
            isReplyTarget : false
		}
	},

    beforeRouteEnter: (to, from, next) => {
        if (to.query.post == undefined) {
            store.state.api
                .post(`/people/${store.state.user.id}/posts/drafts`, {type: 'mensaje'})
                .then(draft => next(vm => {
					if (to.params.people && to.params.people.length) {
						vm.audience = to.params.people;
					}
                    vm.post = draft;
                }));
        } else {
            store.state.api
                .get(`/posts/${to.query.post}`)
                .then(post => next(vm => {
					if (to.params.people && to.params.people.length) {
						vm.audience = to.params.people;
					}
					vm.post = post;

					if (post.replyTo) {
						vm.fetchTrail();
					}

                    if (to.params.paramReplyTarget) {
                        vm.isReplyTarget = true;
                    }
                }));
        }
	},

	beforeRouteLeave (to, from, next) {
		if (this.isSent || this.discard()) {
			next();
		}
		next(false);
	},

	mounted() {
		if (this.$route.query.fw != undefined) {
			var fwIds = typeof this.$route.query.fw == "object" ? this.$route.query.fw : [this.$route.query.fw];
			fwIds.forEach(fwId => {
				this.$store.state.api
					.get(`/posts/${fwId}`)
					.then(post => {
						this.forwards.push(post);

						if (this.post.title == null) {
							this.post.title = 'RV: ' + post.title;
						}
					});
			});
		}
	},

	methods: {
		send() {
			if (!this.audience.length) {
				return alert(this.$t('notice.noPeopleSelected'));
			}

			this.post.audience = {
				to: {
					people: this.audience.map(person => String(person.id))
				}
			};

            this.post.settings = {
                allowed: {
                    reply: true,
                    replyAll: true
                }
            };

			if (this.forwards.length) {
				this.post.quotes = this.forwards.map(quote => quote.id);
			}

			this.isSent = true;

			this.$store.state.api
				.post(`/people/${this.$store.state.user.id}/posts/sent`, this.post)
				.then(() => this.$router.go(-1));
		},

		discard() {
			if (!confirm(this.$t('question.discardThisDraft'))) {
				return false;
			}

			if (this.post.id) {
				this.$store.state.api.delete(`/posts/${this.post.id}`);
			}

			return true;
		},

		fetchTrail() {
			if (!this.post || !this.post.id || !this.post.replyTo) {
				return;
			}

            return this.$store.state.api.get(`/posts/${this.post.id}/trail`, {limit: 5})
                .then(trail => this.trail = trail);
		},

		getPersonName(person) {
			if (typeof person.firstname != "undefined") {
				return person.firstname + " " + person.lastname;
			}

			if (typeof person.firstName != "undefined") {
				return person.firstName + " " + person.lastName;
			}

			return "?";
		}
	},

    computed: {
        sanitizedAudience() {
            return this.audience.map(person => {
                if (typeof person.firstname != 'undefined') {
                    this.$set(person, 'firstName', person.firstname);
                    this.$set(person, 'lastName', person.lastname);
                }
                return person;
            });
        }
    }
}
</script>

<style lang="scss">
#compose {
	background-color: #1C89B8;

	.compose-form {
		.field-to {
			color: #666;
			padding: 12px 8px;
			font-size: 1em;
			font-weight: normal;

			border-bottom: 1px solid #eee;
		}

		.phi-post-editor-header {
			& > input,
			& > textarea {
				color: #666;
				padding: 12px 8px;
				font-size: 1em;
				font-weight: normal;

				border-bottom: 1px solid #eee;
			}
		}
	}


	.page-toolbar {
		color: #fff;

		h1 {
			color: #fff;
		}
	}

	.phi-page-contents {
		padding: 0;
		background-color: #fff;
	}

	.quotes {
		padding: 12px;
		font-size: 0.9em;

		.quote {
			margin-left: 6px;
			border-left: 2px solid dodgerblue;

			.post-author {
				font-weight: bold;
				color: #000;
			}

			.post-description {
				white-space: nowrap;
			}

			.post-blocks {
				margin-top: 12px;
			}
		}
	}

	.namelist {
		& > *::after {
			content: ", ";
		}

		& > :last-child::after {
			content: "";
		}
	}

	.compose-trail {
		.trail-toggler {
			margin: 0;
			padding: 6px 8px;

			& > span {
				display: inline-block;
				padding: 0 16px;
				border-radius: 4px;
				background-color: #eee;
			}
		}

		.trail-contents {
			padding: 0 6px;
			padding-bottom: 12px;
		}
	}

}
</style>